<template>
    <div class="wrapper">
        <div class="top">
            <Language/>
        </div>
        <div class="bottom">
            <UsernamePasswordForm @callback="callback">
                <template slot="title">
                    <span style="font-size: 18px; font-weight: bold;">{{$t('login.chaozan')}}</span>
                </template>
<!--                <template slot="forgetPassword">-->
<!--                    <el-row>-->
<!--                        <el-col class="forget-password pointer">-->
<!--                            <span @click="()=>{-->
<!--                             this.$router.replace('/login/forgetPasswordSecurityCode');-->
<!--                            }">-->
<!--&lt;!&ndash;                                {{$t('login.forgetPassword')}}&ndash;&gt;-->
<!--                            </span>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
<!--                </template>-->
<!--                <template slot="bottom">-->
<!--                    <el-row>-->
<!--                        <el-col>{{$t('login.noAccount')}}<span class="text-color" @click="()=>{-->
<!--                             this.$router.replace('/login/register')-->
<!--                        }">{{$t("login.freeRegistration")}}</span></el-col>-->
<!--                    </el-row>-->
<!--&lt;!&ndash;                    <ThirdPartyLogin :mobile-login="true" :we-chat-login="true"/>&ndash;&gt;-->
<!--                </template>-->
            </UsernamePasswordForm>
        </div>
    </div>
</template>

<script>
    import ThirdPartyLogin from "../../../components/login/ThirdPartyLogin";
    import CommonLogin from "../../../utils/CommonLogin";
    import Language from "../../../components/login/Language";
    import UsernamePasswordForm from "../../../components/login/UsernamePasswordForm";

    export default {
        name: "UsernamePassword",
        components: {UsernamePasswordForm, Language, ThirdPartyLogin},
        data(){
            return {

            }
        },
        mounted() {

        },
        methods: {
            callback(result){
                if (result){
                    this.$router.replace("/manager")
                }
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    @input-width: 400px;
    .wrapper {
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top {
            width: 100%;
            height: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding-right: 50px;
            padding-top: 7px;
        }

        .bottom {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .pointer {
        cursor: pointer;
    }
    .text-color {
        .pointer;
        color:  @color-primary;
    }
    .forget-password{
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        margin-top: -21px;
        color: #666666;
    }
</style>
